<template>
    <div class="home">
        <x-header >首页</x-header>
        <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
            <bounce-loading></bounce-loading>
        </x-content>
        <x-content v-else  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
            <div class="search_warp">
                <el-row class="list-control_audit" :gutter="10">
                    <el-col :span="4">
                        <div class="num">
                            <i class="el-icon-user" style="color: #67c23a;"></i>
                            <h4>科研人员</h4>
                            <h3>{{ toFixed(auditNumData.userNum?auditNumData.userNum:0) }}</h3>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="num">
                            <i class="el-icon-folder-opened" style="color: #E6A23C;"></i>
                            <h4>全部项目</h4>
                            <h3>{{ toFixed(auditNumData.totalProjectNum?auditNumData.totalProjectNum:0) }}</h3>
                        </div>
                    </el-col> 
                    <el-col :span="4">
                        <div class="num">
                            <i class="el-icon-folder" style="color: #E6A23C;"></i>
                            <h4>进行中项目</h4>
                            <h3>{{ toFixed(auditNumData.progressProjectNum?auditNumData.progressProjectNum:0) }}</h3>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="num">
                            <i class="el-icon-folder-checked" style="color: #E6A23C;"></i>
                            <h4>已归档项目</h4>
                            <h3>{{ toFixed(auditNumData.archivedProjectNum?auditNumData.archivedProjectNum:0) }}</h3>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="num">
                            <i class="el-icon-document-add"></i>
                            <h4>已提交实验</h4>
                            <h3>{{ toFixed(auditNumData.submitExerimentNum?auditNumData.submitExerimentNum:0) }}</h3>
                        </div>
                    </el-col> 
                    <el-col :span="4">
                        <div class="num">
                            <i class="el-icon-document"></i>
                            <h4>已复核实验</h4>
                            <h3>{{ toFixed(auditNumData.reviewedExerimentNum?auditNumData.reviewedExerimentNum:0) }}</h3>
                        </div>
                    </el-col> 
                    <el-col :span="4">
                        <div class="num">
                            <i class="el-icon-document-checked"></i>
                            <h4>已归档实验</h4>
                            <h3>{{ toFixed(auditNumData.archivedExerimentNum?auditNumData.archivedExerimentNum:0) }}</h3>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="search_warp" style="margin-top:40px">
              <el-form label-width="70px" v-model="formItem">
                <el-row class="list-control_warp" :gutter="10">
                  <el-col :span="4">
                    <el-form-item  label="报告编号" style="">
                      <el-input  placeholder="请输入审计报告编号" alwaysShowClear clearable v-model="formItem.auditId" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item  label="审计员">
                      <el-input  placeholder="请输入审计员姓名" alwaysShowClear clearable v-model="formItem.auditName" />
                    </el-form-item>
                  </el-col>
                  <el-col :md="9" :xl="7">
                    <el-form-item  label="生成时间">
                      <el-date-picker style="border:1px solod"
                                      v-model="formItem.createDate"
                                      type="daterange"
                                      range-separator="至"
                                      start-placeholder="开始日期"
                                      end-placeholder="结束日期"
                                      :picker-options="pickerOptions">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-button style="padding:8.5px;"  size="medium" type="primary"  @click="listAudit" icon="el-icon-search">
                      检索
                    </el-button>
                    <el-button  style="padding:8.5px;"  size="medium" type="info"  @click="clearSearch" icon="el-icon-refresh">
                      重置
                    </el-button>
                  </el-col>
                  <el-col :span="4">
                    <div class="sort_set" >
                      <el-select placeholder=""  v-model="sort" @change="listAudit"  style="width:50%;">
                        <el-option
                            v-for="item in sortOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                      <span style="cursor: pointer;margin-left:5px" @click="changeSortType" v-html="sortTypeHtml"></span>
                    </div>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          <x-card>
            <el-table
                :data="auditList"
                stripe
                ref="auditList"
                style="width: 100%">
              <el-table-column
                  prop="id"
                  label="审计报告编号">
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  label="生成时间">
              </el-table-column>
              <el-table-column
                  prop="auditName"
                  label="审计员">
              </el-table-column>
              <el-table-column
                  width="250"
                  label="操作">
                <template slot-scope="scope" >
                  <el-button   size="mini" @click="download(scope.row)" type="primary">下载 </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="page-warp">
              <el-pagination
                  @current-change="listAudit"
                  :hide-on-single-page="total<pageSize"
                  background
                  layout="total,prev, pager, next"
                  :total="total"
                  :page-size="pageSize"
                  :current-page.sync="page"
              ></el-pagination>
            </div>
          </x-card>
        </x-content>
        <app-dialog ref="addDialog" dialogTitle="复核签名" originTitle staticDialog width="600px">
          <el-form :model="userSignature" ref="userSignatureForm" >
            <div style="display: flex;justify-content: center;">
              <vue-esign
                  ref="esign"
                  class="mySign"
                  :width="600"
                  :height="300"
                  :isCrop="false"
                  :lineWidth="6"
                  lineColor="#000000"
                  bgColor.sync="bgColor"
              />
            </div>
            <el-form-item >
              <el-input v-model="userSignature.password" show-password @clear="userSignature.password = ''" placeholder="请输入签名密码，密码格式为6位数字，下次输入密码即可完成电子签名"></el-input>
            </el-form-item>
          </el-form>
          <div class="save_btn_warp">
            <el-button type="warning" style="width:20%;" size="medium" @click="resetSign">清空签名</el-button>
            <el-button type="primary" style="width:20%;" size="medium" @click="saveSignImg">确定</el-button>
          </div>
        </app-dialog>
        <app-dialog  ref="userSignatureDialog" dialogTitle="验证签名密码" originTitle staticDialog width="600px">
          <div>
            <el-form >
              <el-form-item  v-model="password">
                <el-input  v-model="password" show-password placeholder="请输入签名密码"></el-input>
              </el-form-item>
              <div class="save_btn_warp">
                <el-button type="primary" @click="verify" style="width:200px;" size="medium" :loading="saveLoading">确定</el-button>
              </div>
            </el-form>
          </div>
        </app-dialog>

    </div>
</template>
<script>
import {ExportWordOrPdf, GetAuditNumData, SearchExperiment} from "@/service/experiment";
import XCard from '../../components/busiess/XCard.vue';
import {ListAudit,DownloadAudit} from "@/service/audit";
import {GetSignature, SignatureSave, Verify} from "@/service/userSignature";
  export default {
  components: { XCard },
    name:'ExperimentAudit',
    data(){
        return {
            saveLoading:false,
            loading:false,
            auditNumData:{},
            page:1,
            total:0,
            pageSize:10,
            formItem:{
              auditId:'',
              auditName:'',
              createDate:'',
            },
            sortOption: [{
              value: 'create_time',
              label: '生成时间'
            }, {
              value: 'auditId',
              label: '审计报告编号'
            }, {
              value: 'auditName',
              label: '审计员'
            }],
            sortTypeHtml:'降序排序<i class="el-icon-sort-down"></i>',
            sortType:'desc',
            sort:'create_time',
            startDate:'',
            endDate:'',
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() > Date.now();
              }
            },
            auditList:[],
            password:'',
            userSignature:{},
            auditId:'',
        }
    },
   	created(){
        this.GetAuditNUmData();
        this.listAudit();
    },
    methods: {
      GetAuditNUmData() {
        GetAuditNumData().then(e => {
          let data = this.APIParse(e);
          this.auditNumData = data;
        })
      },
      toFixed(val) {
        return parseFloat(val).toLocaleString('en', {
          maximumFractionDigits: 2,
        })
      },
      changeSortType() {
        if (this.sortType == 'desc') {
          this.sortType = 'asc';
          this.sortTypeHtml = '升序排序<i class="el-icon-sort-up"></i>'
          this.listAudit();
          return
        }
        if (this.sortType == 'asc') {
          this.sortType = 'desc';
          this.sortTypeHtml = '降序排序<i class="el-icon-sort-down"></i>'
          this.listAudit();
          return
        }
      },
      clearSearch(){
        this.formItem.auditId='';
        this.formItem.auditName='';
        this.endDate='';
        this.startDate='';
        this.formItem.createDate='';
        this.listAudit();
      },
      listAudit(){
          this.loading = true;
          if (this.formItem.createDate&&this.formItem.createDate.length>0){
            this.startDate=this.formItem.createDate[0];
            this.endDate=this.formItem.createDate[1];
          }else {
            this.startDate='';
            this.endDate='';
          }
           ListAudit(
              this.formItem.auditId,
              this.formItem.auditName,
              this.startDate,
              this.endDate,
              this.sort,
              this.sortType,
              this.page,
              this.pageSize,
          ).then(e => {
            let data = this.APIParse(e);
            this.auditList=data.records;
            this.total = data.total || 0;
          }).catch(e => {
            this.$message.error(e.message)
          }).finally(e => {
            this.loading = false;
          })
      },
      download(row){
        this.auditId=row.id;
        GetSignature().then(e => {
              if (e.data) {
                this.userSignature = e.data;
                this.signImg =  this.userSignature.signature;
                this.$refs['userSignatureDialog'].show()
              } else {
                this.$refs['addDialog'].show()
              }
            }
        ).catch(e => {
          this.$message.error(e.message)
        }).finally(e => {
        })
      },
      downloadAudit(){
        DownloadAudit(this.auditId).then(e => {
          let data = this.APIParse(e);
          if(data.base64){
            this.$message.success("下载成功")
            this.downBlobToPage(data.base64,data.fileName)
            this.clearSelection();
          }
        })
      },
         resetSign(){
        this.$refs.esign.reset();
      },
      verify(){
        Verify(this.password).then(e => {
          let data = this.APIParse(e);
          if (data) {
            //this.$message.success("校验成功")
            this.$refs['userSignatureDialog'].hide()
            this.password=''
            this.downloadAudit()
          }else{
            this.$message.error("签名密码输入错误，请检查")
            this.password='';
          }
        })
      },
      saveSignImg(){
        if(!this.userSignature.password){
          this.$message({
            type: "warning",
            message: "请输入签名密码",
          });
          return
        }
        var patten =/^\d{6}$/;
        if (!patten.test(this.userSignature.password)) {
          this.$message("请输入正确的密码格式");
          return false;
        }
        this.$refs.esign
            .generate() // 使用生成器调用把签字的图片转换成为base64图片格式
            .then((res) => {
              // 构造 FormData，并将签名图片对象和签名密码作为字段传入
              this.signImg = res;
              this.userSignature.signature=res
              if(!this.userSignature.signature){
                this.$message({
                  type: "warning",
                  message: "您还没有签名",
                });
                return
              }
              SignatureSave(this.userSignature).then(e => {
                if(e.code ==-1){
                  this.$message.error(e.msg)
                  return;
                }
                let data = this.APIParse(e)
                this.downloadAudit()
                this.hideReview()
              })
            })
            .catch((err) => {
              // 画布没有签字时会执行这里提示一下
              this.$message({
                type: "warning",
                message: "您还没有签名",
              });
            });
          },
          hideReview(){
            this.$refs['userSignatureDialog'].hide()
            this.$refs['addDialog'].hide();
          },
    }

  }
</script>
<style lang="scss" scoped>
    .Width-100{width: 100%}
    .home{width: 100%;height: 100%;overflow: auto;}
    .search_warp{width:100%;margin: 16px 0px;}
    .list-control_audit .base-col-4{
        width: 14.2857%;
        .num{
            background-color: #fff;
            //box-shadow: 0 0 10px rgba(61, 85, 167, 0.1);
            padding: 10px;
            border-radius: 5px;
            height: 130px;
            text-align: center;
            border: 1px solid #fff;
            //background-color: rgba(238, 243, 255,1);
            i{
                margin-top: 10px;
                font-size: 42px;
                color:#2F3A91;
            }
            h4{
                color:#999;
                font-size: 15px;
                font-weight: normal;
                margin: 15px 0px;
                letter-spacing: 1px;
            }
            h3{
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 10px;
            }
        }
    }
</style>